<template>
  <div class="container" ref="container">
        
    <div style="display: flex;justify-content:flex-start;flex-flow:wrap;" 
    :style="'backgroun1:#ffeeff;'">
        <div ref="EChartCol" style="position:relative;padding:20px;min-width:350px;min-height:500px;max-width:600px;max-height:600px;" 
        :style="'background1:#ffeeff;height:'+bannerHeight+'px;width:'+bannerWidth+'px;'">
          <div ref="EChartStation" style="border: 1px solid #fff;
          min-width:350px;min-height:500px;max-width:600px;max-height:600px;width:100%;height:100%"
           >
          </div>
          <el-card style="position:absolute;right:12%;bottom:10%;width:180px;padding:0px;
          display: flex;flex-direction:column;" shadow1="never">
            <el-input size="mini" v-model="SaleSl.zsl" readonly
                 style="margin-bottom:5px;width:100%;">

              <el-dropdown size="mini" @command="dropdownCommand" slot="prepend" style="width:32px;margin:0 auto;cursor: pointer;">
                <div style="font-size:12px;">{{SaleZq}}<i class="el-icon-arrow-down el-icon--right"></i></div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="本日">本日</el-dropdown-item>
                  <el-dropdown-item command="本月">本月</el-dropdown-item>
                  <el-dropdown-item command="本年">本年</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-input>
            <el-input size="mini" v-model="SaleSl.sl1" readonly
                 style="margin-bottom:5px;width:100%;">
                 <div style="width:32px;margin:0 auto" slot="prepend">国产</div>
              </el-input>
              <el-input size="mini" v-model="SaleSl.sl2" readonly
                 style="margin-bottom:5px;">
                 <div style="width:32px;margin:0 auto" slot="prepend">进口</div>
              </el-input>
              <el-input size="mini" v-model="SaleSl.sl3" readonly
                 style="margin-bottom:5px;">
                 <div style="width:32px;margin:0 auto" slot="prepend">50kg</div>
              </el-input>
              <el-input size="mini" v-model="SaleSl.sl4" readonly
                 style="margin-bottom:0px;">
                 <div style="width:32px;margin:0 auto" slot="prepend">报修</div>
              </el-input>
          </el-card>
        </div>
        <div style="position:relative;padding:20px;display: flex;justify-content:space-between;flex-direction:column;
        min-width:350px;min-height:500px;max-width:600px;max-height:600px;" 
        :style="'height:'+bannerHeight+'px;width:'+bannerWidth+'px;'">
          <div ref="EChartSalePie" style="width:100%;height:47%;border: 1px solid #0022f5;padding-top1:20px;"></div>
          <div  ref="EChartSaleBar" style="width:100%;height:47%;border: 1px solid #0022f5;"></div>
        </div>
        

    </div>
  </div>
</template>

<script>
/* eslint-disable */
var echarts = require('echarts')
export default {
  data() {
    return {
      size: 'medium',//medium,small,mini,
      isPc:false,
      SaleSl:{sl1:"",sl2:"",sl3:"",zsl:"",sl4:""},
      SaleZq:"本日",
      updatedata:false,
      bannerWidth:400,
      bannerHeight:400,
      //edata:[],
      stationOld:[],
      totalOld:0,
      EChartStat:null,
      optionStat : {
          title: {
            text: '销售实况',
            top: '10px',
            left: 'left',
            textStyle: {
              color: '#333',
              //fontWeight: 'normal',
              //fontSize: 14
            }
          },
          tooltip: {trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }},
          toolbox: {
            show: false,
            feature: {
              //dataView: { show: true, readOnly: true },
              //magicType: { show: true, type: ['line', 'bar'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          grid: {
            top: '8%',
            left: '2%',
            right: '6%',
            bottom: '1%',
            containLabel: true
          },
          xAxis: {
            //max: 'dataMax',
             max:function (value) {
                 return Math.ceil((value.max+5)/10)*10;
             },
            axisLabel: {
              interval: 0,
              fontSize:14
              //rotate: 30
            },
          },
          yAxis: {
            type: 'category',
            data: ['A', 'B', 'C', 'D'],
            inverse: true,//是否反向坐标
            animationDuration: 300,
            animationDurationUpdate: 300,
            axisLabel: {
              interval: 0,
              fontSize:13
              //rotate: 30
            },
            splitLine: {
              show:false
            },
            dataZoom: [
              {
                type: 'inside'
              }
            ],
            max: 3 // only the largest 3 bars will be displayed
          },
          series: [
            {
              realtimeSort: true,
              name: '小计',
              type: 'bar',
              data: [],
              stack: 'totalbj',
              itemStyle: {
                color: 'rgba(246, 246, 246, 0.8)'
                
              },
              emphasis: {
                focus: 'series'
              },
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(246, 246, 246, 0.8)'
              },
              //barWidth:"1px",
              barGap: "-100%",
              //barCategoryGap:'8%',
              label: {
                show: true,
                position: 'right',
                valueAnimation: true,
                formatter: '{c}{time| {b}}',
                rich: {
                  time: {
                      color: 'red',
                  },
                },
                textStyle:{
                  fontSize:13
                },
              }
            },
            {
              //realtimeSort: true,
              name: '报修',
              type: 'bar',
              data: [],
              stack: 'total',
              emphasis: {
                focus: 'series'
              },
              silent: true,
              label: {
                show: true,
                //position: 'right',
                valueAnimation: true
              }
            },
            {
              //realtimeSort:true,
              name: '50kg',
              type: 'bar',
              data: [],
              stack: 'total',
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true,
                position1: 'right',
                valueAnimation: true
              }
            },
            {
              //realtimeSort: true,
              name: '进口',
              type: 'bar',
              data: [],
              stack: 'total',
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true,
                position1: 'right',
                valueAnimation: true
              }
            },
            {
              //realtimeSort: true,
              name: '国产',
              type: 'bar',
              data: [],
              stack: 'total',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#83bff6' },
                  { offset: 0.5, color: '#188df0' },
                  { offset: 1, color: '#188df0' }
                ]),
                
              },
              emphasis: {
                focus: 'series'
              },
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(230, 230, 230, 0.2)'
              },
              //barWidth:"14px",
              //barGap: "10%",
              barCategoryGap:'8%',
              label: {
                show: true,
                //position: 'right',
                valueAnimation: true,
                textStyle:{
                  color:'rgba(246, 246, 246, 0.9)',
                  fontSize:13
                },
              }
            },

          ],
          legend: {
            show: true,
            top:"10px",
            left:"20%",
            textStyle:{
              fontSize:13
            },
          },
          animationDuration: 500,
          animationDurationUpdate: 200,
          animationEasing: 'linear',
          animationEasingUpdate: 'linear'
        },
      EChartPie:null,
      optionPie : {
        title: {
          text: '预约单执行情况',
          left: 'center',
          top: '5%',
        },
        tooltip: {
          trigger: 'item'
        },
        grid: {
            top: 'auto',
            left: '2%',
            right: '2%',
            bottom: '1%',
            containLabel: true,
          },
        series:{
          name: '执行效率',
          type: 'pie',
          radius: [20, 60],
          top:  '20%',
          //center: ['50%', '60%'],
          //height: '80%',
          left: 'center',
          width: '80%',
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1
          },
          label: {
            alignTo: 'edge',
            formatter: '{name|{b}}\n{value|{c} 单}',
            minMargin: 10,
            edgeDistance: 10,
            lineHeight: 25,
            fontSize: 14,
            rich: {
              time: {
                fontSize: 13,
                color: '#666'
              }
            }
          },
          labelLine: {
            length: 15,
            length2: 1,
            maxSurfaceAngle: 80,
          },
          labelLayout: function (params) {
            const isLeft = params.labelRect.x < 500 / 2;
            const points = params.labelLinePoints;
            // Update the end point.
            points[2][0] = isLeft
              ? params.labelRect.x
              : params.labelRect.x + params.labelRect.width;
            return {
              labelLinePoints: points
            };
          },
          data: [
            { name: '圣彼得堡来客', value: 1 },
            { name: '陀思妥耶夫斯基全集', value: 32 },
            { name: '史记精注全译（全6册）', value: 105 },
            { name: '加德纳艺术通史', value: 290 },
            { name: '表象与本质', value: 590 },
            
            
          ],
        }
      
      },
      optionBar : {
        title: {
          text: '预约单上报情况',
          left: 'center',
          top: '5%',
        },
        grid: {
            top: '40%',
            left: '5%',
            right: '5%',
            bottom: '5%',
            containLabel: true,
            width: "85%", // 宽度
            height: "50%"// 高度
          },
        xAxis: {
          type: 'category',
          data: ['满瓶入库', '满瓶出库', '满瓶送达', '空瓶回库', '空瓶回厂'],
              axisPointer: {
            type: 'shadow'
          }
        },
        yAxis:[
          {
            type: 'value',
            //name: '数量',
            //min: 0,
            //max: 250,
            //interval: 50,
            //axisLabel: {
            //  formatter: '{value} '
            //}
          },
          {
            type: 'value',
            //name: '上报率',
            min: 0,
            max: 100,
            interval: 50,
            //show:false,
            axisLabel: {
              formatter: '{value} %'
            },
            splitLine: { show: false },

          },
        ],
        legend: {
          data: ['发生数', '上报数', '上报率'],
          top: '18%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        series: [
          {
            name: '发生数',
            data: [200,199,99.99,100,80],//, [200,199,99.99], [200,199,99.99], [100,79,79],[300,285,98]],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          },
          {
            name: '上报数',
            data:  [199,199,90.99,98,70],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          },
          {
            name: '上报率',
            data:  [99.9,100.0,98.2,98.0,89.2],
            type: 'line',
            yAxisIndex: 1,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
                show: true,
                //position: 'right',
                valueAnimation: true,
                formatter: '{c} %'
              }
          },
        ]
      },
      cdatetime:"-1",
      mytimers:[],//定时器数组，便于清理
    }
  },
  watch: {
    tabBarWidth(){
      this.initDivSize();
    },
  },
  computed: {
    tabBarWidth(){
      return this.$store.getters.GettabBarInfo("tabBarWidth");
    }
  },
  methods: {
    myclearTimer(){
      this.mytimers.forEach(e=>{
        if (e) clearTimeout(e)
      })
      this.mytimers=[];
    },
    dropdownCommand(e){
      this.SaleZq=e
    },
    mao(){
      //this.EChartStat = echarts.init(this.$refs.mao);
      //this.EChartStat.setOption(this.optionStat);
      if (this.EChartPie==null){
        this.EChartPie = echarts.init(this.$refs.EChartSalePie);
      }
      if (this.EChartBar==null){
        this.EChartBar = echarts.init(this.$refs.EChartSaleBar);
      }
      //this.optionStat.series[3].data=this.edata;
      //this.optionStat.series[3].realtimeSort=true
      //this.myoption.series.forEach(e => {
        //e.realtimeSort=true
      //})
      // 设置参数
      //console.log(this.myoption.series)
      this.EChartPie.setOption(this.optionPie);
      this.EChartBar.setOption(this.optionBar);
      //let option=this.EChart.option;
      //this.EChart.setOption(option);
    },
    getscenedata(){
      if (!this.updatedata) return
      let dataq = {
        lx: "getscenedata",
        sqlone:this.cdatetime,
        sqltwo:this.SaleZq,
        procext:"order"
      };
      this.$api.post("",dataq,(res) => {
        console.log("拿到数据。。。。",res)
          if (res["status"] == "1") {
            this.SaleSl.sl1=res.sl1;
            this.SaleSl.sl2=res.sl2;
            this.SaleSl.sl3=res.sl3;
            this.SaleSl.sl4=res.sl4;
            this.SaleSl.zsl=res.zsl;
            //let yAxis=[];
            if (this.totalOld!=parseInt(res.sl1)){
              this.totalOld=parseInt(res.sl1)
              if (this.stationOld.length==0){
                this.stationOld=res.records
                this.stationOld.forEach(e=>{e.oldvalue=0})
              }
              let m_legend=[];
              res.recordc.forEach(e=>{
                m_legend.push(e.m_legend)
              })
              m_legend.forEach((e,index)=>{
                let data=[]
                this.stationOld.forEach(f=>{
                  let idx=res.recordd.findIndex((item)=>{
                    return item.m_station==f.station && item.m_legend==e;
                  })
                  if (idx>-1){
                    let wcsl=parseInt(res.recordd[idx].wcsl)
                    if (index==0){
                      let valn={value:wcsl,name:(wcsl==f.oldvalue?"":"+"+(wcsl-f.oldvalue))}
                      f.oldvalue=wcsl
                      data.push(valn)
                    }else{
                      data.push(wcsl)
                    }
                  }else{ 
                    data.push("-")
                  }
                })
              
                this.optionStat.series[index].data=data;
                //this.edata=data;
              })
              
              this.optionStat.yAxis.data=this.stationOld.map(e=>e.station);
              this.optionStat.yAxis.max=(this.stationOld.length-1);

              if (this.SaleZq=='本月' || this.SaleZq=='本年'){
                this.optionStat.series[0].label.show=false
                this.optionStat.series[1].label.show=false
              }else{
                this.optionStat.series[0].label.show=true
                this.optionStat.series[1].label.show=true
              }
              this.EChartStat.setOption(this.optionStat,false);
            }
            let pie=res.recorde
            pie.push(pie.shift());
             this.optionPie.series.data=pie
             this.EChartPie.setOption(this.optionPie,false); 
             let b=res.recordf[0];    
             this.optionBar.series[0].data=[b.rk2,b.ps2,b.xs2,b.kp2,b.hs2]
             this.optionBar.series[1].data=[b.rk1,b.ps1,b.xs1,b.kp1,b.hs1]
             this.optionBar.series[2].data=[b.rk3,b.ps3,b.xs3,b.kp3,b.hs3]
             this.EChartBar.setOption(this.optionBar,false);
             this.cdatetime=res.sqlone
            this.mytimers.push(setTimeout( ()=> {
              this.getscenedata();
            }, this.cdatetime==''?7000:10));
            return;
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    initDivSize() {
      this.isPc=this.$store.getters.GettabBarInfo("isPc");
      let divwidth=this.$refs.container.offsetWidth;
      this.bannerWidth=divwidth/2-60;
      this.bannerHeight=(this.$refs.container.offsetHeight-50);
      //tabBarWidth=tabBarWidth
       console.log(this.isPc,divwidth,this.bannerHeight)
       //this.EChartStat.resize();
      // this.$refs.EChartCol.style.width=divwidth 
      // this.$refs.EChartCol.style.height=divheight-30
      if (this.EChartStat) this.EChartStat.resize();
      if (this.EChartPie) this.EChartPie.resize();
      if (this.EChartBar) this.EChartBar.resize();
      //if (!this.isPc || divwidth<700){
      //  this.divwidth=divwidth/2-10;
      //}else{
      //  this.divwidth=700;
      //}
      
    },

  },

  created() {
    //this.getscenedata();
    
  },
  mounted() {   
    window.onresize = () => {
      this.initDivSize()
    };
    //this.getRenderer()
    //this.getRenderer1()
    this.mytimers.push(setTimeout( ()=> {
      this.initDivSize();
      // this.EChartStat = echarts.init(this.$refs.EChartStation);
      // this.EChartStat.on('click', function (params) {
      // console.log(params.dataIndex);
      // this.EChartStat.dispatchAction({
      //   type: 'dataZoom',
      //   startValue: dataAxis[Math.max(params.dataIndex - 8 / 2, 0)],
      //   endValue:
      //     dataAxis[Math.min(params.dataIndex + 8 / 2, data.length - 1)]
      //   });
      // });
      
      //this.updatedata=true;
      //this.EChartStat.setOption(this.optionStat);
      //this.getscenedata();
      //this.mao();
    }, 20));
    this.mytimers.push(setTimeout( ()=> {
      this.EChartStat = echarts.init(this.$refs.EChartStation);
      this.EChartPie = echarts.init(this.$refs.EChartSalePie);
      this.EChartBar = echarts.init(this.$refs.EChartSaleBar);
      this.updatedata=true;
      this.getscenedata();
      this.mao();
    }, 100));
  },
  beforeDestroy() {
    this.updatedata=false;
    this.myclearTimer()
  },
}
</script>
<style>

.container {
  width: 100%;
  height: 100%;
  /* background-color: #475669; */
}

  </style>

